@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --aw-dark: #19191f;
  --aw-intense-dark: #0f0f14;
}

@layer base {
  p {
    @apply font-light text-awGray;
  }
}

body {
  color: white;
  background: var(--aw-intense-dark);
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}

h2 {
  @apply font-light text-4xl lg:text-6xl;
}

h4 {
  @apply text-4xl lg:text-6xl;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.btn-primary {
  @apply bg-awSea text-sm lg:text-base tracking-[0.01em] mx-4 text-black -skew-x-[35deg] relative px-8 lg:px-12 py-3 hover:bg-awSeaLight transition-colors flex items-center justify-center;
  scroll-behavior: smooth;
}

.btn-secondary {
  @apply bg-white text-sm lg:text-base text-black tracking-[0.01em] -skew-x-[35deg] relative px-8 lg:px-12 py-4 hover:bg-awGray transition-colors mx-4;
}

.btn-tertiary {
  @apply bg-awGray text-sm lg:text-base text-black tracking-[0.01em] -skew-x-[35deg] relative px-8 lg:px-12 py-4 hover:bg-white transition-colors mx-4;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
